import React from 'react';
import PrimaryButton from '../../../../../../Common/Buttons/PrimaryButton';
import Modal from '../../../../../../Common/Modal';

import styles from './UploadTypologyModal.module.scss';
import { useMutation } from "@apollo/client";
import {
  Field,
  Form,
  Formik
} from "formik";
import * as yup from "yup";
import TextField from "../../../../../../Common/TextField";
import Select from "react-select";
import { CREATE_TAG, CREATE_TROVERA } from "../../../../../api/mutations";
import { GET_TROVERA } from "../../../../../api/queries";

const UploadTroveraModal = ({
  isModalOpen,
  setUploadTagModalOpen,
}) => {
  const initialValues = {
    name: ""
  };
  const validationSchema = {
    name: yup.string().required('Obbligatorio'),
  };
  const [addTrovera] = useMutation(CREATE_TROVERA, {
    refetchQueries: [{
      query: GET_TROVERA,
      variables: {
        filter: {
          getTrovera: true,
        }
      }
    }],
    awaitRefetchQueries: true,
  });
  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setUploadTagModalOpen(false);
    }}
  >
    <div className={styles['modal__upload']}>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={async values => {
          console.log(values.name);
          await addTrovera({
            variables: {
              createTroveraInput: {
                name: values.name
              }
            }
          });
          setUploadTagModalOpen(false);
        }}
      >
        {({
          errors,
          touched,
          setFieldValue
        }) => {
          return (
            <Form>

              <Field name='name' id='name'
                label={`Nome cosa troverai`}
                component={TextField}
                type='text'
                borderRadius='5px'
                inputColor="white"
              />
              <PrimaryButton
                type='submit'
                fontSize='18px'
                text={`Aggiungi tag cosa troverai`}
                width='100%'
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  </Modal>;
};

export default UploadTroveraModal;
