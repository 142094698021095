import clx from 'classnames';
import {
  Field, Form, Formik
} from 'formik';
import React, {
  useRef,
  useState
} from 'react';
import * as yup from 'yup';

import CheckIcon from '../../../../../../../../../assets/svg/check-icon.svg';
import Bin from '../../../../../../../../../assets/svg/bin.svg';
import CloseIcon from '../../../../../../../../../assets/svg/cross-icon.svg';
import PencilImg from '../../../../../../../../../assets/svg/pencil.svg';
import IconButton from '../../../../../../../../Common/Buttons/IconButton';
import PrimaryButton from '../../../../../../../../Common/Buttons/PrimaryButton';
import TextField from '../../../../../../../../Common/TextField';
import styles from './ImageInfo.module.scss';
import {
  EDIT_ESTABLISHMENT,
} from "../../../../../../../api/mutations";
import {useMutation} from "@apollo/client";
import {GET_ESTABLISHMENT} from "../../../../../../../api/queries";
import Select from "react-select";

const ImageInfo = ({
                     details, setConfirmModalOpen, setActiveImage, id, codeValues
                   }) => {
  const [editImageInfoFormOpen, setEditImageInfoFormOpen] = useState(false);
  const submitFormRef = useRef();
  const [editEstablishmentDetails] = useMutation(EDIT_ESTABLISHMENT, {
    refetchQueries: [{
      query: GET_ESTABLISHMENT,
      variables: {
        establishmentInput: {
          id: Number(id),
        }
      }
    }],
    awaitRefetchQueries: true,
  });

  const initialValues = {
    establishmentId: id,
    detailsId: details?.id,
    title: details?.title || '',
    description: details?.description || '',
    code: details?.code || '',
  };

  const validationSchema = yup.object().shape({
    title: yup
    .string('Inserisci nome immagine'),
    description: yup
    .string('Inserisci descrizione immagine'),
    code: yup
    .string('Inserisci codice immagine'),
  });
  return <>
    {details ?
      <div className={styles.image__main}>
        <div className={styles.image__wrapper}>
          <img src={details?.image?.url} alt="house" className={styles['house-image']}/>
        </div>
        <div className={styles.image__info}>
          <div className={styles.actions__wrapper}>
            {editImageInfoFormOpen ?
              (<>
                  <PrimaryButton
                    text='Salva modifica'
                    leftIconSrc={CheckIcon}
                    onClick={() => submitFormRef.current.handleSubmit()}
                    fontSize="10px"
                    height='25px'
                    padding='6px 10px'
                    borderRadius='5px'
                  />
                  <IconButton
                    padding='0'
                    onClick={() => {
                      submitFormRef.current.resetForm();
                      setEditImageInfoFormOpen(false);
                    }}
                    iconSrc={CloseIcon}
                  />
                </>
              ) :
              (
                <>
                  <IconButton
                    onClick={() => setEditImageInfoFormOpen(true)}
                    backgroundColor='#5E239D'
                    iconSrc={PencilImg}
                  />
                  <IconButton
                    onClick={() => {
                      setConfirmModalOpen(true);
                      setActiveImage(details);
                    }}
                    iconSrc={Bin}
                  />
                </>
              )}
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async value => {
              await editEstablishmentDetails({
                variables: {
                  editEstablishmentInput: {
                    id: value.establishmentId,
                    details: {
                      id: value.detailsId,
                      description: value.description,
                      code: value.code,
                      title: value.title
                    }
                  }
                }
              });
              submitFormRef.current.resetForm();
              setEditImageInfoFormOpen(false);
            }}
            innerRef={submitFormRef}
          >
            {({
                errors,
                touched,
                values,
                setFieldValue
              }) => (
              <Form className={clx(styles['edit-image__form'], {[styles['form__disabled']]: !editImageInfoFormOpen})}>
                <div style={{paddingBottom: '10px', maxWidth: '250px'}}>
                  <Select
                    name="code"
                    placeholder={"Seleziona codice"}
                    value={{value: values.code, label: values.code}}
                    onChange={data => setFieldValue("code", data.value)}
                    options={codeValues?.map(item => ({value: item, label: item}))}
                    className="basic-multi-select"
                    isDisabled={!editImageInfoFormOpen}
                    classNamePrefix="select"
                  />
                </div>
                {
                  touched.code &&
                  errors.code && <div className={styles.error}>{errors.code}</div>
                }
                <Field name='title' id='title'
                       component={TextField}
                       type='text'
                       borderRadius='5px'
                       disabled={!editImageInfoFormOpen}
                       placeholder='Titolo'
                />
                <Field name='description' id='description'
                       component={TextField}
                       type='text'
                       borderRadius='5px'
                       disabled={!editImageInfoFormOpen}
                       placeholder='Descrizione'
                       textarea={true}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div> : null
    }
  </>;
};

export default ImageInfo;
