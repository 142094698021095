import clx from 'classnames';
import {
	useState
} from 'react';

import BinImage from '../../../../../../../../../assets/svg/bin.svg';
import IconButton from '../../../../../../../../Common/Buttons/IconButton';
import ConfirmRemoveImageModal from '../ConfirmRemoveImageModal';
import styles from './GalleryList.module.scss';

const SortableItem = ( { image, establishmentId } ) => {
	const [ confirmModalOpen, setConfirmModalOpen ] = useState( false );

	return ( <div
		className={ clx( styles['list-item__wrapper'], styles['list-item__draggable'] ) }
	>
		<img src={ image.url } alt="match"
			className={ styles.list__img } />
		<div className={ styles.btn__remove }>
			<IconButton
				iconSrc={ BinImage }
				onClick={ () => {
					setConfirmModalOpen( true);
				}}
				height='30px'
				width='30px'
				padding='7px'
				borderRadius='50%'
				backgroundColor='#FFF7FA'
			/>
		</div>
		<ConfirmRemoveImageModal
			confirmModalOpen={ confirmModalOpen } setConfirmModalOpen={ setConfirmModalOpen }
			fieldId={establishmentId}
			idImage={image?.id}
		/>
	</div> );
};


export default SortableItem;
