export const fileValidator = (files, config) => {
	const {
		allowedFileFormats, fileSizeMBLimit, filesLimit
	} = config;
	const { length } = files;
	const { size, type } = files[0];
	let err = false;
	let result = {
		isValidFile: false,
		errVal: err
	};
	if (length === 0) {
		return result;
	} else if (length > filesLimit) {
		err = filesLimit > 1 ? `È consentito caricare solo ${filesLimit} file` : 'È consentito caricare un solo file';
	} else if (!allowedFileFormats.includes(type)) {
		err = 'Il formato del file deve essere png o jpg';
	} else if (size / 1024 / 1024 > fileSizeMBLimit) {
		err = `La dimensione del file ha superato il limite di ${fileSizeMBLimit}MB`;
	} else {
		result.isValidFile = true;
	}
	result.errVal = err;
	return result;
};

export const preventBrowserDefaults = e => {
	e.preventDefault();
	e.stopPropagation();
};
