import clx from 'classnames';
import {
  createRef, useState
} from 'react';

import Logo from '../../../../../../assets/images/logo.png';
import ArrowImg from '../../../../../../assets/svg/arrow-nero.svg';
import AvatarImg from '../../../../../../assets/svg/avatar.svg';
import { ReactComponent as DoorImg } from '../../../../../../assets/svg/door.svg';
import LogoutImg from '../../../../../../assets/svg/logout.svg';
import { ReactComponent as NotebookImg } from '../../../../../../assets/svg/notebook.svg';
import { RoutePath } from '../../../../../../routers/constants';
import useOutsideClick from '../../../../../../utils/useOutsideClick';

import SecondaryButton from '../../../../../Common/Buttons/SecondaryButton';
import { sideBarRoutes } from '../../../../constants';
import styles from './AdminTopBar.module.scss';
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import { GET_ME } from "../../../../api/queries";

const AdminTopBar = ({ activeTab }) => {
  const menuRef = createRef();
  const navigation = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(null);
  const {
    // loading,
    // error,
    data
  } = useQuery(GET_ME);

  useOutsideClick(menuRef, () => {
    if (isMenuOpen) setIsMenuOpen(false);
  });

  const logout = () => {
    localStorage.removeItem('token');
    navigation(RoutePath.SIGN_IN);
  };

  return (
      <div className={styles.navi__group}>
    <div className={styles.topbar}>
      <img className={styles.logo} src={Logo} alt="logo" />
      <div className={styles['user-info__wrapper']}
        onClick={() => setIsMenuOpen(true)}
      >
        <div className={styles.user__info}>
          <img src={AvatarImg} alt="user avatar" className={styles.user__avatar} />
          <span className={styles.user__name}>{data?.me ? `${data?.me?.firstName} ${data?.me?.lastName}` : ''}</span>
          <img src={ArrowImg} alt='arrow' className={clx(styles.menu__arrow, { [styles.arrow__up]: isMenuOpen })} />
        </div>
        {isMenuOpen && <div ref={menuRef} className={styles.dropdown__menu}>
          <SecondaryButton
            leftIconSrc={LogoutImg}
            text='Logout'
            fontSize='18px'
            color='#FC440F'
            onClick={logout}
          />
        </div>}
      </div>
    </div>
      <div className={styles.navigation}>
        <nav role="navigation" className={styles.primary__navigation}>
          <ul>
            {sideBarRoutes.map((route, idx) => {
              const checkIsActive = activeTab?.path.includes(route.path);
              return (
                <li key={idx}
                  className={clx(styles.nav__item, { [styles['nav__active-tab']]: checkIsActive })}
                  onClick={() => navigation(`/${route.path}`)}
                >
                  <span className={styles['nav-item__label']}>{route.label}</span>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
      </div>
  );
};

export default AdminTopBar;
