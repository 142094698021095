import React from 'react';
import Modal from '../../../../../../Common/Modal';
import { useMutation } from "@apollo/client";
import { DELETE_TAG } from "../../../../../api/mutations";
import { GET_TAGS } from "../../../../../api/queries";
import ConfirmModal from "../../../../../../Common/ConfirmModal";

const DeleteTypologyModal = ({
  isModalOpen,
  setDeleteTagModalOpen,
  TagIdToDelete
}) => {
  const [deleteTag] = useMutation(DELETE_TAG, {
    refetchQueries: [GET_TAGS],
    awaitRefetchQueries: true,
  });

  const confirmModal = async () => {
    await deleteTag({
      variables: {
        deleteTagInput: TagIdToDelete
      }
    });
    setDeleteTagModalOpen(false);
  };
  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setDeleteTagModalOpen(false);
    }}
  >
    <ConfirmModal
      setConfirmModalOpen={() => {
        setDeleteTagModalOpen(false);
      }}
      confirmModal={confirmModal}
    />
  </Modal>;
};

export default DeleteTypologyModal;
