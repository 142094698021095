import React from 'react';
import PrimaryButton from '../../../../../../Common/Buttons/PrimaryButton';
import Modal from '../../../../../../Common/Modal';

import styles from './UploadFacilityModal.module.scss';
import {useMutation} from "@apollo/client";
import {
  Field,
  Form,
  Formik
} from "formik";
import * as yup from "yup";
import TextField from "../../../../../../Common/TextField";
import {CREATE_FACILITY} from "../../../../../api/mutations";
import {GET_FACILITIES} from "../../../../../api/queries";

const UploadFacilityModal = ({isModalOpen, setUploadFacilityModalOpen}) => {
  const initialValues = {
    name: ""
  };

  const validationSchema = {
    name: yup.string().required('Obbligatorio'),
  };
  const [addDisability] = useMutation(CREATE_FACILITY, {
    refetchQueries: [GET_FACILITIES],
    awaitRefetchQueries: true,
  });

  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setUploadFacilityModalOpen(false);
    }}
  >
    <div className={styles['modal__upload']}>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={async values => {
          await addDisability({
            variables: values
          });
          setUploadFacilityModalOpen(false);
        }}
      >
        {() => {
          return (
            <Form>
              <Field name='name' id='name'
                     label='Nome Ausilio'
                     component={TextField}
                     type='text'
                     borderRadius='5px'
                     inputColor="white"
              />
              <PrimaryButton
                type='submit'
                fontSize='18px'
                text='Aggiungi Ausilio'
                width='100%'
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  </Modal>;
};

export default UploadFacilityModal;
