import React from 'react';
import Modal from '../../../../../../Common/Modal';
import {useMutation} from "@apollo/client";
import {DELETE_CATEGORY} from "../../../../../api/mutations";
import {GET_CATEGORIES, GET_TYPOLOGIES} from "../../../../../api/queries";
import ConfirmModal from "../../../../../../Common/ConfirmModal";

const DeleteTypologyModal = ({
                               isModalOpen,
                               setDeleteCategoryModalOpen,
                               categoryIdToDelete
                             }) => {
  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    refetchQueries: [GET_TYPOLOGIES, GET_CATEGORIES],
    awaitRefetchQueries: true,
  });

  const confirmModal = async () => {
    await deleteCategory({
      variables: {
        categoryId: categoryIdToDelete
      }
    });
    setDeleteCategoryModalOpen(false);
  };
  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setDeleteCategoryModalOpen(false);
    }}
  >
    <ConfirmModal
      setConfirmModalOpen={() => {
        setDeleteCategoryModalOpen(false);
      }}
      confirmModal={confirmModal}
    />
  </Modal>;
};

export default DeleteTypologyModal;
