import React from 'react';
import Modal from '../../../../../../Common/Modal';
import {useMutation} from "@apollo/client";
import {DELETE_TYPOLOGY} from "../../../../../api/mutations";
import {GET_TYPOLOGIES} from "../../../../../api/queries";
import ConfirmModal from "../../../../../../Common/ConfirmModal";

const DeleteTypologyModal = ({
                               isModalOpen,
                               setDeleteTypologyModalOpen,
                               typologyIdToDelete
                             }) => {
  const [deleteTypology] = useMutation(DELETE_TYPOLOGY, {
    refetchQueries: [GET_TYPOLOGIES],
    awaitRefetchQueries: true,
  });

  const confirmModal = async () => {
    await deleteTypology({
      variables: {
        typologyId: typologyIdToDelete
      }
    });
    setDeleteTypologyModalOpen(false);
  };
  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setDeleteTypologyModalOpen(false);
    }}
  >
    <ConfirmModal
      setConfirmModalOpen={() => {
        setDeleteTypologyModalOpen(false);
      }}
      confirmModal={confirmModal}
    />
  </Modal>;
};

export default DeleteTypologyModal;
