import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import jwtDecode from "jwt-decode";
import {RoutePath} from "../routers/constants";

export const SetSession = ({children, isPrivate}) => {
  const token = localStorage.getItem('token');
  const navigation = useNavigate();
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    if (token && isPrivate) {
      const decode = jwtDecode(token);
      const date = new Date().getTime();
      if (decode.exp > date) {
        setIsAuth(false);
        navigation(RoutePath.SIGN_IN);
        localStorage.removeItem('token');
      } else {
        setIsAuth(true);
        // localStorage.removeItem('token');
      }
      return;
    }
    setIsAuth(false);
    navigation("/login");
    localStorage.removeItem('token');
  }, [token, isPrivate, navigation]);

  return isAuth ? children : null;
};
