import React from 'react';
import Modal from '../../../../../../Common/Modal';
import {useMutation} from "@apollo/client";
import {DELETE_DISABILITY} from "../../../../../api/mutations";
import {GET_DISABILITIES} from "../../../../../api/queries";
import ConfirmModal from "../../../../../../Common/ConfirmModal";

const DeleteDisabilityModal = ({
                                 isModalOpen,
                                 setDeleteDisabilityModalOpen,
                                 disabilityIdToDelete
                             }) => {
  const [deleteDisability] = useMutation(DELETE_DISABILITY, {
    refetchQueries: [GET_DISABILITIES],
    awaitRefetchQueries: true,
  });

  const confirmModal = async () => {
    await deleteDisability({
      variables: {
        disabilityId: disabilityIdToDelete
      }
    });
    setDeleteDisabilityModalOpen(false);
  };
  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setDeleteDisabilityModalOpen(false);
    }}
  >
    <ConfirmModal
      setConfirmModalOpen={() => {
        setDeleteDisabilityModalOpen(false);
      }}
      confirmModal={confirmModal}
    />
  </Modal>;
};

export default DeleteDisabilityModal;
