import styles from "./CategoriesTypologies.module.scss";
import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import PlusIcon from "../../../../../assets/svg/plus.svg";
import UploadTagModalOpen from "./containers/UploadTagModal";
import DeleteTagModalOpen from "./containers/DeleteTagModal";
import CategoriaDetails from './CategoriaDetails';
import { GET_TAGS } from "../../../api/queries";


const TableSezione = ({ Categoria, Sezioni }) => {
  const tags = useQuery(GET_TAGS, { variables: { "filter": { "idCategoria": Number(Categoria.id) } } });
  const Tags = tags?.data?.GetAllTagsAndFields?.tags;
  const [uploadTagModalOpen, setUploadTagModalOpen] = useState(false);
  const [deleteTagModalOpen, setDeleteTagModalOpen] = useState(false);
  const [TagIdToDelete, setTagIdToDelete] = useState(false);
  if (Categoria.name === 'Servizi') {
    Sezioni = Sezioni?.filter(item => item.name !== 'Tipologia');
  }
  return (

    <>
      <UploadTagModalOpen
        isModalOpen={uploadTagModalOpen}
        categoria={Categoria}
        setUploadTagModalOpen={setUploadTagModalOpen}
        sezioni={Sezioni}
      />
      <DeleteTagModalOpen
        setDeleteTagModalOpen={setDeleteTagModalOpen}
        isModalOpen={deleteTagModalOpen}
        TagIdToDelete={TagIdToDelete}
      />
      <div className={styles.categoriesAndTypologies__wrapper}>
        <div className={styles.actions}>
          <label htmlFor={"categories"}>{Categoria?.name}</label>
          <PrimaryButton
            onClick={() => setUploadTagModalOpen(true)}
            text={`crea ${Categoria?.name}`}
            leftIconSrc={PlusIcon}
            fontSize="10px"
            height='25px'
            width='fit-content'
            padding='6px 10px'
            borderRadius='10px'
            background='#078EA6'
          />
        </div>
        {Sezioni?.map((Sezione, index) =>
          <CategoriaDetails key={index}
            setTagIdToDelete={setTagIdToDelete}
            setDeleteTagModalOpen={setDeleteTagModalOpen}
            Sezione={Sezione}
            Tags={Tags?.filter(Tag => Tag.idSezione === Sezione.idSezione)} />
        )}
      </div>
    </>

  );
};

export default TableSezione;


