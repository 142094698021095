import Modal from '../../../../../../../../Common/Modal';
import {DELETE_PICTURE_FROM_ESTABLISHMENT} from "../../../../../../../api/mutations";
import {useMutation} from "@apollo/client";
import {GET_ESTABLISHMENT} from "../../../../../../../api/queries";
import ConfirmModal from "../../../../../../../../Common/ConfirmModal";

const ConfirmRemoveImageModal = ( {
	confirmModalOpen, setConfirmModalOpen, fieldId, idImage
} ) => {
  const [addPictureToEstablishment] = useMutation(DELETE_PICTURE_FROM_ESTABLISHMENT, {
    refetchQueries: [{
      query: GET_ESTABLISHMENT,
      variables: {
        establishmentInput: {
          id: Number(fieldId),
        }
      }
    }],
    awaitRefetchQueries: true,
  });

  const confirmModal = async () => {
      await addPictureToEstablishment({
        variables: {
          dropEstablishmentPictureInput: {
            establishmentId: fieldId,
            pictureId: idImage
          }
        }
      });
      setConfirmModalOpen(false);
  };

	return <Modal
		isModalOpen={confirmModalOpen}
		setIsModalOpen={ () => setConfirmModalOpen(false) }
		height='200px'
	>
    <ConfirmModal
      confirmModal={confirmModal}
      setConfirmModalOpen={() => setConfirmModalOpen(false)}
    />
	</Modal>;
};

export default ConfirmRemoveImageModal;
