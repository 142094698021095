
import { useQuery } from "@apollo/client";
import React from "react";
import { GET_CATEGORIES, GET_SEZIONI } from "../../../api/queries";
import TableSezione from "./TableTags";
import TableTrovera from "./TableTrovera";

const CategorieSezioniTables = ({ idEstablishment }) => {
  const categorie = useQuery(GET_CATEGORIES);
  const sezioni = useQuery(GET_SEZIONI);
  var Sezioni = sezioni?.data?.GetAllTagsAndFields?.sezioni;
  var Categorie = categorie?.data?.establishmentCategories;
  return (
    <>
      {Categorie?.map((Categoria, index) =>
        <TableSezione key={index} Sezioni={Sezioni} Categoria={Categoria} />
      )
      }
      < TableTrovera />

    </>
  );
};

export default CategorieSezioniTables;
