import './styles/index.scss';

import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import muiTheme from './themes/muiTheme';
import SignIn from "./Containers/Auth/containers/SignIn/SignIn";
import { Navigate } from 'react-router';
import { AdminDashboard } from "./Containers/Dashboard/containers";
import { SetSession } from "./utils/SetSession";
import { RoutePath } from "./routers/constants";

export default props => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <main className='container'>
        <BrowserRouter>
          <SetSession isPrivate={true}>
            <Routes>
              <Route
                path={"/:tabName"}
                element={<AdminDashboard />} />
              <Route
                path={"/:tabName/:id"}
                element={<AdminDashboard />} />
              <Route path="/" element={<Navigate replace to={RoutePath.CLIENTS} />} />
            </Routes>
          </SetSession>
          {/*<SetSession isPrivate={false}>*/}
          <Routes>
            <Route path={RoutePath.SIGN_IN} element={<SignIn />} />
          </Routes>
          {/*</SetSession>*/}
        </BrowserRouter>
      </main>
    </MuiThemeProvider>
  );
};
