import PrimaryButton from "../Buttons/PrimaryButton";
import styles from "./ConfirmModal.module.scss";

const ConfirmModal = ( {confirmModal, setConfirmModalOpen} ) => {
  return     <>
    <h2 className={styles['confirm-modal__title']}>Sicuro di voler eliminare?</h2>
    <div className={styles['confirm-modal__actions']}>
      <PrimaryButton
        text='Si'
        fontSize='18px'
        onClick={confirmModal}
        height='25px'
        width='80px'
        padding='15px 20px'
        borderRadius='5px'
      />
      <PrimaryButton
        text='No'
        fontSize='18px'
        onClick={ () => setConfirmModalOpen(false) }
        height='25px'
        width='80px'
        padding='15px 20px'
        borderRadius='5px'
        background='#FC440F'
      />
    </div>
  </>;
};

export default ConfirmModal;
