import clx from 'classnames';
import {
  useEffect, useState
} from 'react';

import CloseIcon from '../../../../../../../../../assets/svg/cross-icon.svg';
import GridIcon from '../../../../../../../../../assets/svg/grid.svg';
import StarIcon from '../../../../../../../../../assets/svg/star.svg';
import IconButton from '../../../../../../../../Common/Buttons/IconButton';
import styles from './GalleryList.module.scss';
import SortableItem from './SortableItem';
import {ReactSortable} from "react-sortablejs";
import {useMutation} from "@apollo/client";
import {EDIT_ESTABLISHMENT} from "../../../../../../../api/mutations";
import {GET_ESTABLISHMENT} from "../../../../../../../api/queries";

const GalleryList = ({
                       gallery, id, activeImage, setActiveImage, coverId
                     }) => {
  const [isChangingOrder, setIsChangingOrder] = useState(false);
  const [sortedGallery, setSortedGallery] = useState([]);
  const [editEstablishmentImagePosition, images] = useMutation(EDIT_ESTABLISHMENT, {
    refetchQueries: [{
      query: GET_ESTABLISHMENT,
      variables: {
        establishmentId: Number(id)
      }
    }],
    awaitRefetchQueries: true,
  });
  useEffect(() => {
    if (gallery) {
      setSortedGallery(gallery);
    }
  }, [gallery, id]);

  useEffect(() => {
    if (sortedGallery.length) {
      const pictureIds = sortedGallery.map(picture => picture.id);
      const oldPictureIds = gallery.map(picture => picture.id);
      if (!pictureIds.every((value, index) => value === oldPictureIds[index])) {
        editEstablishmentImagePosition({
          variables: {
            editEstablishmentInput: {
              id,
              pictureIds,
            }
          }
        });
      }
    }
  }, [sortedGallery]);

  return <>
    {isChangingOrder ?
      <div className={styles.actions__wrapper}>
        <IconButton
          padding='0'
          onClick={() => {
            setIsChangingOrder(false);
          }
          }
          iconSrc={CloseIcon}
        />
      </div> :
      null
    }
    {isChangingOrder ?
      <ReactSortable
        className={styles.gallery__list}
        group="groupName"
        preventOnFilter={true}
        setList={setSortedGallery}
        list={sortedGallery}
        filter={".static"}
        animation={200}
      >
        {sortedGallery?.map((image, index) =>
          <div
            key={index}
            className={images.loading ? "static" : "active"}
          >
            <SortableItem
              index={index}
              image={image}
              establishmentId={id}
            />
          </div>
        )}
      </ReactSortable> :
      (
        <div className={styles.gallery__list}>
          {gallery?.map((image, idx) => <div
              key={idx}
              className={styles['list-item__wrapper']}
              onClick={() => setActiveImage(image)}
            >
              {image.id === coverId ?
                (
                  <div className={clx(styles.cover__image, 'cover-image-tooltip')}>
                    <img src={StarIcon} alt='cover'/>
                  </div>) :
                null}
              <img src={image.url} alt="match"
                   className={clx(styles.list__img, {[styles['active-image']]: activeImage?.id === image?.id})}/>
            </div>
          )}
          {gallery?.length ?
            (
              <div className={styles['change-order-btn__wrapper']}>
                <IconButton
                  padding='25px'
                  height='75px'
                  width='75px'
                  borderRadius='50%'
                  onClick={() => setIsChangingOrder(true)}
                  iconSrc={GridIcon}
                  backgroundColor='#FFF7FA'
                />
              </div>
            ) :
            null}
        </div>
      )}
  </>;
};

export default GalleryList;
