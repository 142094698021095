import clx from 'classnames';
import {
	Field, Form, Formik
} from 'formik';
import { useRef, useState} from 'react';
import * as yup from 'yup';

import CheckIcon from '../../../../../../../../../assets/svg/check-icon.svg';
import CrossIcon from '../../../../../../../../../assets/svg/close.svg';
import CloseIcon from '../../../../../../../../../assets/svg/cross-icon.svg';
import MenuIcon from '../../../../../../../../../assets/svg/menu-icon.svg';
import PencilImg from '../../../../../../../../../assets/svg/pencil.svg';
import StarIcon from '../../../../../../../../../assets/svg/star.svg';
import useOutsideClick from '../../../../../../../../../utils/useOutsideClick';
import IconButton from '../../../../../../../../Common/Buttons/IconButton';
import PrimaryButton from '../../../../../../../../Common/Buttons/PrimaryButton';
import Modal from '../../../../../../../../Common/Modal';
import TextField from '../../../../../../../../Common/TextField';
import styles from './ActiveImageInfo.module.scss';
import {
  ADD_COVER_PICTURE,
  EDIT_ESTABLISHMENT_PICTURE
} from "../../../../../../../api/mutations";
import {useMutation} from "@apollo/client";
import {GET_ESTABLISHMENT} from "../../../../../../../api/queries";

const ActiveImageInfo = ( {
	activeImage, setConfirmModalOpen, setUploadImageModalOpen, id, coverId
} ) => {
	const [ isMenuOpen, setIsMenuOpen ] = useState( false );
	const [ editImageInfoFormOpen, setEditImageInfoFormOpen ] = useState( false );
	const [ changeCoverImageModalOpen, isChangeCoverImageModalOpen ] = useState( false );

	const menuRef = useRef();
	const submitFormRef = useRef();

  const [editEstablishmentPicture] = useMutation(EDIT_ESTABLISHMENT_PICTURE, {
    refetchQueries: [{
      query: GET_ESTABLISHMENT,
      variables: {
        establishmentInput: {
          id: Number(id),
        }
      }
    }],
    awaitRefetchQueries: true,
  });

  const [addCoverPicture] = useMutation(ADD_COVER_PICTURE, {
    refetchQueries: [{
      query: GET_ESTABLISHMENT,
      variables: {
        establishmentInput: {
          id: Number(id),
        }
      }
    }],
    awaitRefetchQueries: true,
  });

	useOutsideClick( menuRef, () => {
		if ( isMenuOpen ) setIsMenuOpen( false );
	} );

	const initialValues = {
    establishmentId: id,
    pictureId: activeImage?.id,
		title: activeImage?.title || '',
		description: activeImage?.description || '',
	};

	const validationSchema = yup.object().shape({
    title: yup
			.string( 'Inserisci titolo immagine' ),
    description: yup
			.string( 'Inserisci descrizione immagine' ),
	} );

	const AddImageButton = () => (
		<div className={styles['add-image-btn__wrapper']}>
			<IconButton
				height='75px'
				width='75px'
				padding='25px'
				backgroundColor='#0C97AB'
				borderRadius='50%'
				onClick={ () => setUploadImageModalOpen(true) }
				iconSrc={ CrossIcon }
			/>
		</div>
	);
	return <>
		{activeImage ?
			<div className={ styles.image__main }>
				<div className={ styles.image__wrapper }>
					{ activeImage?.id === coverId ?
						(
							<div className={ clx(styles.cover__image, 'cover-image-tooltip') }>
								<img src={ StarIcon } alt='cover' />
							</div>) :
						null }
					<img src={ activeImage?.url } alt="house" className={ styles['house-image'] } />
					<IconButton
						height='20px'
						width='5px'
						padding='0'
						onClick={ () => setIsMenuOpen(true) }
						iconSrc={ MenuIcon }
					/>
					{ isMenuOpen && <div ref={ menuRef } className={ styles.dropdown__menu }>
						<span
							className={styles.menu__item}
							onClick={ () => isChangeCoverImageModalOpen( true ) }
						>Imposta nuova copertina</span>
						<span
							className={clx(styles.menu__item, styles['remove-item-btn'])}
							onClick={ () => setConfirmModalOpen( true ) }
						>Elimina Foto</span>
					</div> }
				</div>
				<div className={ styles.image__info }>
					<div className={ styles.actions__wrapper }>
						{ editImageInfoFormOpen ?
							( <>
								<PrimaryButton
									text='Salva modifica'
									leftIconSrc={ CheckIcon }
									onClick={ () => submitFormRef.current.handleSubmit() }
									fontSize="10px"
									height='25px'
									padding='6px 10px'
									borderRadius='5px'
								/>
								<IconButton
									padding='0'
									onClick={ () => {
										submitFormRef.current.resetForm();
										setEditImageInfoFormOpen( false );
									} }
									iconSrc={ CloseIcon }
								/>
							</>
							) :
							(
								<IconButton
									onClick={ () => setEditImageInfoFormOpen( true ) }
									backgroundColor='#5E239D'
									iconSrc={ PencilImg }
								/>
							) }
					</div>
					<Formik
						enableReinitialize
						initialValues={ initialValues }
						validationSchema={ validationSchema }
            onSubmit={value => {
              editEstablishmentPicture({
                variables: {
                  editEstablishmentPictureInput: value
                }
              });
              setConfirmModalOpen(false);
              setEditImageInfoFormOpen(false);
            }}
						innerRef={ submitFormRef }
					>
						{ ( { errors, touched } ) => (
							<Form className={ clx(styles['edit-image__form'], { [styles['form__disabled']]: !editImageInfoFormOpen } )}>
								<Field name='title' id='title'
									component={ TextField }
									type='text'
									borderRadius='5px'
									disabled={ !editImageInfoFormOpen }
									placeholder='Titolo'
								/>
								<Field name='description' id='description'
									component={ TextField }
									type='text'
									borderRadius='5px'
									disabled={ !editImageInfoFormOpen }
									placeholder='Descrizione'
									textarea={ true }
								/>
							</Form>
						) }
					</Formik>
					<AddImageButton />
				</div>
			</div> :
			<div className={ styles['empty-gallery'] }>
				<p>Non ci sono foto</p>
				<AddImageButton />
			</div> }
		<Modal
			isModalOpen={changeCoverImageModalOpen}
			setIsModalOpen={ () => isChangeCoverImageModalOpen( false ) }
			height='200px'
		>
			<h2 className={styles['change-cover-modal__title']}>Sei sicuro dei cambiamenti?</h2>
			<div className={styles['change-cover-modal__actions']}>
				<PrimaryButton
					text='Cambia'
          onClick={() => {
            addCoverPicture({
              variables: {
                addCoverPictureInput: {
                  establishmentId: id,
                  pictureId: activeImage.id
                }
              }
            });
            isChangeCoverImageModalOpen( false );
          }}
					fontSize="18px"
					height='auto'
					padding='10px'
					borderRadius='5px'
				/>
				<PrimaryButton
					text='Rimani come prima'
					onClick={ () => isChangeCoverImageModalOpen( false ) }
					fontSize='18px'
					height='auto'
					padding='10px'
					borderRadius='5px'
					background='#FC440F'
				/>
			</div>
		</Modal>
	</>;
};

export default ActiveImageInfo;
