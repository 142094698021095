import clx from "classnames";
import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import styles from "../Containers/Common/TextField/TextField.module.scss";

function GoogleAutocomplete({
  field,
  form: { touched, errors },
  className,
  label,
  type = "text",
  inputColor = "bianco",
  borderRadius,
  readonly,
  textarea = false,
  height = "50px",
  setFieldValue,
  disabled,
}) {
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyDzLkBY6l1c0atoxCLVV0uB5KTDgnGewng",
    onPlaceSelected: place => {
      handleChangeIndirizzo(place);
    },
    options: {
      types: ["geocode", "establishment"],
    },
  });

  function handleChangeIndirizzo(place) {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    console.log(place, "PLACE");
    let cap = 0;
    let numero = 0;
    let citta = "";
    let provincia = "";
    let regione = "";
    let nazione = "";
    let via = "";
    for (var i = 0; i < place.address_components.length; i++) {
      for (var j = 0; j < place.address_components[i].types.length; j++) {
        if (place.address_components[i].types[j] === "postal_code") {
          cap = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] === "street_number") {
          numero = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] === "administrative_area_level_3") {
          citta = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] === "administrative_area_level_2") {
          provincia = place.address_components[i].short_name;
        } else if (place.address_components[i].types[j] === "administrative_area_level_1") {
          regione = place.address_components[i].long_name;
        } else if (place.address_components[i].types[j] === "country") {
          nazione = place.address_components[i].short_name;
        } else if (place.address_components[i].types[j] === "route") {
          via = place.address_components[i].long_name;
        }
      }
    }
    console.log(cap);
    setFieldValue("country", nazione);
    setFieldValue("regione", regione);
    setFieldValue("provincia", provincia);
    setFieldValue("city", citta);
    setFieldValue("buildingNumber", numero);
    setFieldValue("cap", cap);
    setFieldValue("street", via);
    setFieldValue("lat", lat);
    setFieldValue("lng", lng);
  }

  return (
    <div>
      <div
        className={clx(styles.field__wrapper, {
          [styles["full-height"]]: height,
        })}
      >
        {label && (
          <label className={styles["field__label"]} htmlFor={"autocomplete"}>
            {label}
          </label>
        )}
        <input
          ref={ref}
          id={"autocomplete"}
          className={clx(styles["field__input"], styles[`${inputColor}`])}
          type={type}
          disabled={disabled}
          style={{
            borderRadius: `${borderRadius}`,
            height,
          }}
        />
      </div>
    </div>
  );
}

export default GoogleAutocomplete;
