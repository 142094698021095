import PrimaryButton from '../../../../../../../../Common/Buttons/PrimaryButton';
import ImageInfo from "../ImageInfo";
import styles from "../ImageInfo/ImageInfo.module.scss";
import IconButton from "../../../../../../../../Common/Buttons/IconButton";
import CrossIcon from "../../../../../../../../../assets/svg/close.svg";
import PlusIcon from "../../../../../../../../../assets/svg/plus.svg";
import React from "react";


const GalleryList = ({
                       gallery, setUploadImageModalOpen, id, setConfirmModalOpen, setActiveImage, codeValues
                     }) => {
  return <>
    {
      gallery.length ?
        <div>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: "flex-end"
          }}>
            <PrimaryButton
              text='Aggiungi immagine alla galleria dei dettagli'
              onClick={() => setUploadImageModalOpen(true)}
              leftIconSrc={PlusIcon}
              fontSize="10px"
              height='25px'
              width='fit-content'
              padding='6px 10px'
              borderRadius='10px'
              background='#078EA6'
            />
          </div>
          {gallery?.map((details, index) => <div
              key={index}
              style={{
                width: "100%",
                display: 'flex',
              }}>
              <ImageInfo
                details={details}
                id={id}
                codeValues={codeValues}
                setConfirmModalOpen={setConfirmModalOpen}
                setActiveImage={setActiveImage}
              />
            </div>
          )}
        </div> :
        <div className={ styles['empty-gallery'] }>
          <p>Non ci sono dettagli</p>
          <div className={styles['add-image-btn__wrapper']}>
            <IconButton
              height='75px'
              width='75px'
              padding='25px'
              backgroundColor='#0C97AB'
              borderRadius='50%'
              onClick={() => setUploadImageModalOpen(true)}
              iconSrc={ CrossIcon }
            />
          </div>
        </div>
    }
  </>;
};

export default GalleryList;
