import {setContext} from '@apollo/client/link/context';
import { createUploadLink } from "apollo-upload-client";
import {
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';

const httpLink = createUploadLink({
  // uri: 'https://api-world4all-prod.herokuapp.com/graphql',
  // uri: "http://localhost:5001/graphql"
  uri: "https://w4core.doclive.info/graphql"
});

const authLink = setContext((_, {headers}) => {
  const token = localStorage.getItem('token') || "";
  return {
    headers: {
      ...headers,
      authorization: token,
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  introspection: false,
});
