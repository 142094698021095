import React from 'react';
import PrimaryButton from '../../../../../../Common/Buttons/PrimaryButton';
import Modal from '../../../../../../Common/Modal';

import styles from './UploadTypologyModal.module.scss';
import { useMutation } from "@apollo/client";
import {
  Field,
  Form,
  Formik
} from "formik";
import * as yup from "yup";
import TextField from "../../../../../../Common/TextField";
import Select from "react-select";
import { CREATE_TAG } from "../../../../../api/mutations";
import { GET_TAGS } from "../../../../../api/queries";

const UploadTypologyModal = ({
  isModalOpen,
  setUploadTagModalOpen,
  categoria,
  sezioni,

}) => {
  const initialValues = {
    categoryId: "",
    name: ""
  };
  const validationSchema = {
    idCategoria: yup.string().required('Obbligatorio'),
    name: yup.string().required('Obbligatorio'),
  };
  const [addTypology] = useMutation(CREATE_TAG, {
    refetchQueries: [GET_TAGS],
    awaitRefetchQueries: true,
  });
  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setUploadTagModalOpen(false);
    }}
  >
    <div className={styles['modal__upload']}>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={async values => {
          await addTypology({
            variables: {
              createTagInput: {
                "idCategoria": values.idCategoria,
                "idSezione": values.idSezione,
                "name": values.name
              }
            }
          });
          setUploadTagModalOpen(false);
        }}
      >
        {({
          errors,
          touched,
          setFieldValue
        }) => {
          return (
            <Form>
              <div className={styles.modal__dropdown}>
                <label style={{
                  display: "inline-block",
                  marginBottom: '10px'
                }} htmlFor={"detailsCode"}>Categoria</label>

                <Select
                  name="idCategoria"
                  placeholder={"Seleziona la categoria"}
                  onChange={data => {
                    setFieldValue("idSezione", data.value);
                    setFieldValue("idCategoria", categoria.id);
                  }}
                  options={sezioni.map(item => ({ value: item.idSezione, label: item.name }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {
                  touched.categoryId &&
                  errors.categoryId && <div className={styles.error}>{errors.categoryId}</div>
                }
              </div>
              <Field name='name' id='name'
                label={`Nome ${categoria.name}`}
                component={TextField}
                type='text'
                borderRadius='5px'
                inputColor="white"
              />
              <PrimaryButton
                type='submit'
                fontSize='18px'
                text={`Aggiungi ${categoria.name}`}
                width='100%'
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  </Modal>;
};

export default UploadTypologyModal;
