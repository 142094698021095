import MUIDataTable from 'mui-datatables';
import {memo} from 'react';
import { Link, useLocation } from 'react-router-dom';

import EyeIcon from '../../../assets/svg/eye.svg';
import useWindowSize from '../../../utils/useWindowSize';
import DataTableFooter from './containers/DataTableFooter';

const DataTable = memo(( {
	tableTitle, tableData, tableName, tableColumns, rowsPerPageNum = 10, detailsLink = false
} ) => {
	const { isMobile } = useWindowSize();
	const {pathname} = useLocation();

	const columns = tableColumns?.map( column => (
		{
			name: column?.name,
			label: column?.label,
			options: {
				filter: column?.filter,
				sort: column?.sort,
				setCellHeaderProps: () => column?.centered && !isMobile && ( { className: 'centeredHeader boldHeader' } ),
				setCellProps: () => column?.centered &&  !isMobile && ( { className: 'centeredColumn' } ),
			}
		}
	) );

	const options = {
		filterType: 'checkbox',
		filter: true,
		sort: true,
		responsive: 'vertical',
    selectableRows: false,
    tableBodyMaxHeight: 'auto',
		rowsPerPage: rowsPerPageNum,
		rowsPerPageOptions: [],
		pagination: true,
		textLabels: {
			body: {
				noMatch: 'Nessun oggetto trovato',
			}
		},
		customFooter: ( count, page, rowsPerPage, changeRowsPerPage, changePage, textLabels ) => <DataTableFooter
			count={count}
			page={page}
			rowsPerPage={rowsPerPage}
			changeRowsPerPage={changeRowsPerPage}
			changePage={changePage}
			textLabels={textLabels}
		/>,
	};

	return (
		<div>
			<MUIDataTable
				title={ tableTitle }
				data={
          tableData?.map( item => ( {
						...item,
						options: <Link to={ detailsLink ? `${detailsLink}/${item?.id}` : `${pathname}/${item?.id}` }>
							<img className='eye-icon' src={ EyeIcon } alt="details" />
						</Link>
					}))}
				columns={columns}
				options={ options }
			/>
		</div>
	);
});

export default DataTable;
