import React from 'react';
import Modal from '../../../../../../Common/Modal';
import { useMutation } from "@apollo/client";
import { DELETE_TAG, DELETE_TROVERA } from "../../../../../api/mutations";
import { GET_TAGS, GET_TROVERA } from "../../../../../api/queries";
import ConfirmModal from "../../../../../../Common/ConfirmModal";

const DeleteTroveraModal = ({
  isModalOpen,
  setDeleteTagModalOpen,
  TroveraIdToDelete
}) => {
  const [deleteTrovera] = useMutation(DELETE_TROVERA, {
    refetchQueries: [{
      query: GET_TROVERA,
      variables: {
        filter: {
          getTrovera: true,
        }
      }
    }],
    awaitRefetchQueries: true,
  });

  const confirmModal = async () => {
    console.log(TroveraIdToDelete);
    await deleteTrovera({
      variables: {
        deleteTroveraInput: Number(TroveraIdToDelete)
      }
    });
    setDeleteTagModalOpen(false);
  };
  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setDeleteTagModalOpen(false);
    }}
  >
    <ConfirmModal
      setConfirmModalOpen={() => {
        setDeleteTagModalOpen(false);
      }}
      confirmModal={confirmModal}
    />
  </Modal>;
};

export default DeleteTroveraModal;
