import styles from "./EstablishmentDisabilitiesAndFacilitiesAndTags.module.scss";
import EstablishmentDisabilities from "./containers/Disabilities";
import EstablishmentFacilities from "./containers/Facilities";
import { useQuery } from "@apollo/client";
import { GET_SEZIONI, GET_TROVERA } from "../../../../../api/queries";
import TagsComponent from "./containers/Tags";
import EstablishmentTrovera from "./containers/trovera";

const EstablishmentDisabilitiesAndFacilitiesAndTags = ({
  disabilities,
  facilities,
  id,
  categorie
}) => {
  const sezioni = useQuery(GET_SEZIONI);
  var Sezioni = sezioni?.data?.GetAllTagsAndFields?.sezioni;
  const currentTrovera = useQuery(GET_TROVERA, {
    variables: {
      filter: {
        idEstablishment: [Number(id)],
        getTrovera: true,
      }
    }
  });
  const allTrovera = useQuery(GET_TROVERA, {
    variables: {
      filter: {
        getTrovera: true,
      }
    }
  });
  var AllTrovera = allTrovera?.data?.GetAllTrovera?.trovera;
  var CurrentTrovera = currentTrovera?.data?.GetAllTrovera?.trovera;
  return (
    <div className={styles.facilityAndDisability__wrapper}>
      <div>
        <div className={styles.facilityAndDisability__wrapper}>
          <EstablishmentDisabilities disabilities={disabilities} id={id} />
          <EstablishmentFacilities facilities={facilities} id={id} />
          {AllTrovera && CurrentTrovera && <EstablishmentTrovera id={id} allTrovera={AllTrovera} trovera={CurrentTrovera} />}

        </div>
        <div className={styles.facilityAndDisability__wrapper}>
          {Sezioni?.map((sezione, index) =>
            <TagsComponent
              categorie={categorie}
              key={index}
              sezione={sezione}
              id={id}
              facilities={facilities}
            />
          )}
        </div>

      </div>
    </div>
  );
};


export default EstablishmentDisabilitiesAndFacilitiesAndTags;
