import PrimaryButton from "../../../../Common/Buttons/PrimaryButton";
import Modal from "../../../../Common/Modal";
import Select from "react-select";
import * as yup from "yup";
import './index.css';
import {
    Field, Form, Formik
} from "formik";
import TextField from "../../../../Common/TextField";
import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import styles from "./UploadEstablishmentsModal.module.scss";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../../../routers/constants";
import {
    GET_CATEGORIES, GET_ESTABLISHMENTS, GET_DISABILITIES, GET_FACILITIES, GET_TYPOLOGIES
} from "../../../api/queries";
import { ADD_ESTABLISHMENTS } from "../../../api/mutations";
import PlusIcon from "../../../../../assets/svg/plus.svg";
import GoogleAutocomplete from "../../../../../components/GoogleAutocomplete";

const UploadEstablishmentsModal = ({ isModalOpen, setUploadEstablishmentsModalOpen }) => {
    const navigation = useNavigate();

    const [addEstablishments] = useMutation(ADD_ESTABLISHMENTS, {
        refetchQueries: [
            {
                query: GET_ESTABLISHMENTS,
                variables: {
                    establishmentListInput: {
                        limit: 100000000,
                        offset: 0,
                    },
                },
            },
        ],
        awaitRefetchQueries: true,
    });
    const categories = useQuery(GET_CATEGORIES, {});
    const typologies = useQuery(GET_TYPOLOGIES, {});
    const facilities = useQuery(GET_FACILITIES, {});
    const disabilities = useQuery(GET_DISABILITIES, {});

    const initialValues = {
        email: "",
        name: "",
        phone: "",
        website: "",
        description: "",
        detailsCode: "",
        detailsDescription: "",
        detailsImageId: "",
        detailsImage: "",
        detailsImageBase64: "",
        country: "",
        city: "",
        street: "",
        lat: "",
        lng: "",
        buildingNumber: "",
        apartment: "",
        disabilityIds: [],
        facilityIds: [],
        typologyIds: [],
        categoryIds: [],
    };

    const validationSchema = {
        email: yup.string("Inseirisci la tua email").email("Inserisci email valida"),
        name: yup.string().required("Obbligatorio"),
        phone: yup
            .string()
            .min(3)
            .max(20)
            .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, "Is not in correct format"),
        website: yup.string().url(),
        description: yup.string().required("Obbligatorio"),
        country: yup.string().required("Obbligatorio"),
        city: yup.string().required("Obbligatorio"),
        street: yup.string().required("Obbligatorio"),
        buildingNumber: yup.string().required("Obbligatorio"),
        apartment: yup.string(),
        lat: yup.number().required("Obbligatorio").typeError("Devi specificare un numero"),
        lng: yup.number().required("Obbligatorio").typeError("Devi specificare un numero"),
        disabilityIds: yup.array().min(1, "Obbligatorio"),
        facilityIds: yup.array().min(1, "Obbligatorio"),
        typologyIds: yup.array().min(1, "Obbligatorio"),
        categoryIds: yup.array().min(1, "Obbligatorio"),
    };
    return (
        <Modal
            isModalOpen={isModalOpen}
            setIsModalOpen={() => {
                setUploadEstablishmentsModalOpen(false);
            }}
        >
            <div className="modal_main">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape(validationSchema)}
                    onSubmit={async values => {
                        const {
                            data: { createEstablishment },
                        } = await addEstablishments({
                            variables: {
                                establishmentInput: {
                                    name: values.name,
                                    phone: values.phone,
                                    website: values.website,
                                    email: values.email,
                                    description: values.description,
                                    address: {
                                        country: values.country,
                                        city: values.city,
                                        street: values.street,
                                        buildingNumber: values.buildingNumber,
                                        apartment: values.apartment,
                                        lat: Number(values.lat),
                                        lng: Number(values.lng),
                                    },
                                    disabilityIds: values.disabilityIds,
                                    facilityIds: values.facilityIds,
                                    typologyIds: values.typologyIds,
                                },
                            },
                        });
                        if (createEstablishment.id) {
                            setUploadEstablishmentsModalOpen(false);
                            navigation(`${RoutePath.ESTABLISHMENTS}/${createEstablishment.id}`);
                        }
                    }}
                >
                    {({
                        errors, touched, values, setFieldValue
                    }) => {
                        return (
                            <Form
                                className="main" style={{ flexDirection: 'column' }}
                            >
                                <div
                                    className="main"
                                >
                                    <div className="item">
                                        <Field
                                            name="name"
                                            id="name"
                                            label="Nome attività"
                                            placeholder={"nome attività"}
                                            component={TextField}
                                            type="text"
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                        <Field
                                            name="phone"
                                            id="phone"
                                            label="Contatti"
                                            placeholder={"+39 "}
                                            component={TextField}
                                            type="text"
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                        <Field
                                            name="email"
                                            id="email"
                                            component={TextField}
                                            type="email"
                                            placeholder={"@ email"}
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                        <Field name="lat" id="lat" component={TextField}
                                            type="text" placeholder={"lat"} borderRadius="5px"
                                            inputColor="white" />
                                        <Field name="lng" id="lng" component={TextField}
                                            type="text" placeholder={"lng"} borderRadius="5px"
                                            inputColor="white" />
                                        <Field
                                            name="website"
                                            id="website"
                                            label="Web site"
                                            placeholder={"https:// web site"}
                                            component={TextField}
                                            type="text"
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                    </div>
                                    <div className="item">

                                        <Field
                                            name="autocomplete"
                                            id="autocomplete"
                                            placeholder={"Inserisci indirizzo"}
                                            label="Cerca con Google"
                                            component={GoogleAutocomplete}
                                            setFieldValue={setFieldValue}
                                            type="text"
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                        <Field
                                            name="country"
                                            id="country"
                                            label="Nazione"
                                            placeholder={"es: Italia"}
                                            component={TextField}
                                            type="text"
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                        <Field
                                            name="city"
                                            id="city"
                                            label="Città"
                                            placeholder={"es: Milano"}
                                            component={TextField}
                                            type="text"
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                        <Field
                                            name="street"
                                            id="street"
                                            label="Indirizzo"
                                            placeholder={"es: Via della spiga"}
                                            component={TextField}
                                            type="text"
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                        <Field
                                            name="buildingNumber"
                                            id="buildingNumber"
                                            placeholder={"es: 7"}
                                            label="Civico"
                                            component={TextField}
                                            type="text"
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                        {/* <Field name='apartment' id='apartment'
                         placeholder={"ex: "}
                         label='Apartament'
                         component={TextField}
                         type='text'
                         borderRadius='5px'
                         inputColor="white"
                  /> */}
                                    </div>
                                    <div className="item">

                                        <Field
                                            name="description"
                                            id="description"
                                            label="Descrizione"
                                            placeholder={"Descrivi attività"}
                                            component={TextField}
                                            type="text"
                                            textarea={true}
                                            height={"100%"}
                                            borderRadius="5px"
                                            inputColor="white"
                                        />
                                    </div>
                                </div>
                                <div
                                    className="main"
                                >
                                    <div className="item">
                                        <label
                                            style={{
                                                display: "inline-block",
                                                margin: "25px 0 10px 0",
                                            }}
                                            htmlFor={"categories"}
                                        >
                                            Categorie
                                        </label>
                                        <Select
                                            isMulti
                                            name="categoryIds"
                                            placeholder={"Seleziona Categoria"}
                                            onChange={data => {
                                                const categoryValues = data.map(item => item.value);
                                                setFieldValue("categoryIds", categoryValues);
                                                setFieldValue(
                                                    "typologyIds",
                                                    values.typologyIds.filter(item =>
                                                        categoryValues.includes(
                                                            typologies?.data?.establishmentTypologies?.find(
                                                                typo => Number(typo.id) === Number(item))?.categoryId
                                                        )
                                                    )
                                                );
                                            }}
                                            options={categories?.data?.establishmentCategories?.map(
                                                item => ({ value: item.id, label: item.name }))}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                        {touched.categoryIds &&
                                            errors.categoryIds &&
                                            <div className={styles.error}>{errors.categoryIds}</div>}
                                    </div>
                                    <div className="item">
                                        <label
                                            style={{
                                                display: "inline-block",
                                                margin: "25px 0 10px 0",
                                            }}
                                            htmlFor={"typologies"}
                                        >
                                            Typologies
                                        </label>
                                        <Select
                                            isMulti
                                            name="typologyIds"
                                            placeholder={"Seleziona Tipologia"}
                                            onChange={data =>
                                                setFieldValue(
                                                    "typologyIds",
                                                    data.map(item => item.value)
                                                )
                                            }
                                            value={typologies?.data?.establishmentTypologies
                                                ?.filter(item => values.typologyIds.includes(item.id))
                                                .map(item => ({ value: item.id, label: item.name }))}
                                            options={typologies?.data?.establishmentTypologies
                                                ?.filter(item => values.categoryIds.includes(item.categoryId))
                                                .map(item => ({ value: item.id, label: item.name }))}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                        {touched.typologyIds &&
                                            errors.typologyIds &&
                                            <div className={styles.error}>{errors.typologyIds}</div>}
                                    </div>
                                    <div className="item">
                                        <label
                                            style={{
                                                display: "inline-block",
                                                margin: "25px 0 10px 0",
                                            }}
                                            htmlFor={"disabilities"}
                                        >
                                            Disabilities
                                        </label>
                                        <Select
                                            isMulti
                                            name="disabilityIds"
                                            placeholder={"Seleziona disabilità"}
                                            onChange={data =>
                                                setFieldValue(
                                                    "disabilityIds",
                                                    data.map(item => item.value)
                                                )
                                            }
                                            options={disabilities?.data?.disabilities?.map(item => ({
                                                value: item.id,
                                                label: item.name,
                                            }))}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                        {touched.disabilityIds &&
                                            errors.disabilityIds &&
                                            <div className={styles.error}>{errors.disabilityIds}</div>}
                                    </div>
                                    <div className="item">
                                        <label
                                            style={{
                                                display: "inline-block",
                                                margin: "25px 0 10px 0",
                                            }}
                                            htmlFor={"facilities"}
                                        >
                                            Facilities
                                        </label>
                                        <Select
                                            isMulti
                                            name="facilityIds"
                                            placeholder={"Seleziona Ausili"}
                                            onChange={data =>
                                                setFieldValue(
                                                    "facilityIds",
                                                    data.map(
                                                        item => item.value)
                                                )
                                            }
                                            options={facilities?.data?.facilities?.map(item => ({
                                                value: item.id,
                                                label: item.name,
                                            }))}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                        {touched.facilityIds &&
                                            errors.facilityIds &&
                                            <div className={styles.error}>{errors.facilityIds}</div>}
                                    </div>
                                </div>
                                <div
                                    className="button"
                                >
                                    <div className='the_button'
                                    >
                                        <PrimaryButton
                                            type="submit"
                                            text="Crea Nuova Attività"
                                            leftIconSrc={PlusIcon}
                                            fontSize="10px"
                                            height="25px"
                                            width="fit-content"
                                            padding="6px 10px"
                                            borderRadius="10px"
                                            background="#078EA6"
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </Modal>
    );
};
export default UploadEstablishmentsModal;
