import { useMutation, useQuery } from "@apollo/client";
import { GET_TAGS } from "../../../../../../../api/queries";
import { EDIT_TAGS_TO_ESTABLISHMENT } from "../../../../../../../api/mutations";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Select from "react-select";
import styles from "../../EstablishmentDisabilitiesAndFacilitiesAndTags.module.scss";
import PrimaryButton from "../../../../../../../../Common/Buttons/PrimaryButton";
import React, { useState } from "react";
import CheckIcon from "../../../../../../../../../assets/svg/check-icon.svg";
import IconButton from "../../../../../../../../Common/Buttons/IconButton";
import CloseIcon from "../../../../../../../../../assets/svg/cross-icon.svg";
import PencilImg from "../../../../../../../../../assets/svg/pencil.svg";


const Tags = (
  {
    sezione,
    categorie,
    id
  }) => {
  const currentEstablishmentTags = useQuery(GET_TAGS, {
    variables: {
      filter: {
        idEstablishment: Number(id),
        idSezione: Number(sezione.idSezione),
        idCategoria: categorie,
      }
    }
  });
  const currentEstablishmentTagsAll = useQuery(GET_TAGS, {
    variables: {
      filter: {
        idCategoria: categorie,
        idSezione: Number(sezione.idSezione),
      }
    }
  });
  var allTags = currentEstablishmentTagsAll?.data?.GetAllTagsAndFields?.tags;
  var tags = currentEstablishmentTags?.data?.GetAllTagsAndFields?.tags;
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editEstablishmentFacilities] = useMutation(EDIT_TAGS_TO_ESTABLISHMENT, {
    refetchQueries: [{
      query: GET_TAGS,
      variables: {
        filter: {
          idEstablishment: Number(id),
          idSezione: Number(sezione.idSezione),
          idCategoria: categorie,
        }
      }
    }],
    awaitRefetchQueries: true,
  });
  const validationSchema = {
    idTag: yup.array().min(0, 'Obbligatorio'),
  };
  return (
    <div className={styles.facilityAndDisability__inner}>
      {tags ?
        <Formik
          initialValues={{
            idTag: tags?.map(item => item.idTag),
            establishmentId: id
          }}
          validationSchema={yup.object().shape(validationSchema)}
          onSubmit={async values => {
            console.log(values);

            await editEstablishmentFacilities({
              variables: {
                editTagEstablishmentInput: {
                  idEstablishment: Number(id),
                  idTag: values.idTag || [],
                  idSezione: Number(sezione.idSezione),
                  idCategoria: categorie
                }
              }
            });
            setEditFormOpen(false);
          }}
        >
          {({
            errors,
            touched,
            values,
            setFieldValue
          }) => {
            return (
              <Form>
                <div className={styles.facilityAndDisability__inner__form}>
                  <label htmlFor={`${sezione.name}Ids`}>{sezione.name}</label>
                  {editFormOpen ?
                    (
                      <div className={styles.actions}>
                        <PrimaryButton
                          text='Salva modifica'
                          leftIconSrc={CheckIcon}
                          type={'submit'}
                          fontSize="10px"
                          height='25px'
                          padding='6px 10px'
                          borderRadius='5px'
                        />
                        <IconButton
                          padding='0'
                          onClick={() => setEditFormOpen(false)}
                          iconSrc={CloseIcon}
                        />
                      </div>
                    ) :
                    (
                      <IconButton
                        onClick={() => setEditFormOpen(true)}
                        backgroundColor='#5E239D'
                        iconSrc={PencilImg}
                      />
                    )}
                </div>
                <Select
                  isMulti
                  name="idTag"
                  placeholder={"Seleziona Attività"}
                  isDisabled={!editFormOpen}
                  value={
                    allTags?.filter(item =>
                      values.idTag?.includes(item.idTag))
                      .map(item => ({ value: item.idTag, label: item.name }))

                  }
                  onChange={data => {
                    setFieldValue("idTag", data.map(item => item.value));
                    console.log(data.map(item => item.value));
                  }}
                  options={allTags?.map(item => ({ value: item.idTag, label: item.name }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
                {
                  touched.disabilityIds &&
                  errors.disabilityIds && <div className={styles.error}>{errors.disabilityIds}</div>
                }
              </Form>
            );
          }}
        </Formik> : null}
    </div>
  );
};


export default Tags;
