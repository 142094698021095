import {
	useEffect, useState
} from 'react';

import Modal from '../../../../../../Common/Modal';

import GalleryList from './containers/GalleryList';
import UploadImageModal from './containers/UploadImageModal';
import styles from './DetailsEstablishmentGallery.module.scss';
import {EDIT_DETAILS} from "../../../../../api/mutations";
import {useMutation} from "@apollo/client";
import {GET_ESTABLISHMENT} from "../../../../../api/queries";
import ConfirmModal from "../../../../../../Common/ConfirmModal";

const DetailsEstablishmentGallery = ( { currentEstablishment, id } ) => {
	const [activeImage, setActiveImage] = useState(false);
	const [ confirmModalOpen, setConfirmModalOpen ] = useState( false );
	const [ uploadImageModalOpen, setUploadImageModalOpen ] = useState( false );
  const [editEstablishmentDetails] = useMutation(EDIT_DETAILS, {
    refetchQueries: [{
      query: GET_ESTABLISHMENT,
      variables: {
        establishmentInput: {
          id: Number(id),
        }
      }
    }],
    awaitRefetchQueries: true,
  });
  const values = [
    "ENTRATA",
    "WC",
    "ALTEZZA_TAVOLO",
    "LETTI"
  ];
  const [codeValues, setCodeValues] = useState(values);
  useEffect(() => {
    setCodeValues(values.filter(code => !currentEstablishment.details.some(img => img.code === code)));
  }, [currentEstablishment.details]);

  const confirmModal = async () => {
    await editEstablishmentDetails({
      variables: {
        dropDetailInput: {
          detailId: activeImage.id,
          establishmentId: id
        }
      }
    });
    setUploadImageModalOpen(false);
    setActiveImage(false);
    setConfirmModalOpen(false);
  };

	return <div className={styles.gallery__wrapper}>
		<GalleryList
      gallery={currentEstablishment.details}
			setUploadImageModalOpen={ setUploadImageModalOpen }
      setConfirmModalOpen={setConfirmModalOpen}
      id={currentEstablishment?.id}
			activeImage={ activeImage }
      setActiveImage={setActiveImage}
      codeValues={codeValues}
    />
		<Modal
			isModalOpen={confirmModalOpen}
			setIsModalOpen={ () => setConfirmModalOpen(false) }
			height='200px'
		>
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModalOpen={() => setConfirmModalOpen(false)}
      />
		</Modal>
		<UploadImageModal
			isModalOpen={uploadImageModalOpen}
			setUploadImageModalOpen={setUploadImageModalOpen}
      gallery={currentEstablishment.details}
      id={currentEstablishment?.id}
      codeValues={codeValues}
    />
	</div>;
};

export default DetailsEstablishmentGallery;
