import { useEffect } from 'react';

import { RoutePath } from '../../../../routers/constants';
import { sideBarRoutes } from '../../constants';
import ClientsTable from '../Clients/ClientsTable';
import styles from './AdminDashboard.module.scss';
import AdminTopBar from './containers/AdminTopBar';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import EstablishmentsTable from "../Establishments/EstablishmentsTable";
import DisabilitiesFacilitiesTable from "../DisabilitiesFacilities/DisabilitiesFacilitiesTable";
import CategoriesTypologiesTable from "../CategoriesTypologies/CategoriesTypologiesTable";
import Tags from '../Tags/TagsTables';

export default () => {
  let params = useParams();
  const navigation = useNavigate();

  const activeTab = sideBarRoutes?.find(route => route.path === params.tabName);
  useEffect(() => {
    if (!activeTab) {
      navigation(RoutePath.CLIENTS);
    }
  }, [activeTab, navigation]);

  return (
      <div className={styles.main__content}>
          <AdminTopBar activeTab={activeTab} />
      <div className={styles.container__inner}>
          <div className={styles.content}>
            {
              activeTab?.path === 'clients' ?
                <ClientsTable id={params?.id} /> :
                activeTab?.path === 'establishments' ?
                  <EstablishmentsTable id={params?.id} /> :
                  activeTab?.path === 'categoriesTypologies' ?
                    <CategoriesTypologiesTable /> :
                    activeTab?.path === 'tags' ?
                      <Tags /> :
                      activeTab?.path === 'disabilitiesFacilities' ?
                        <DisabilitiesFacilitiesTable /> : null
            }
          </div>
      </div>
      </div>
  );
};
