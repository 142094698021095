import clx from 'classnames';

import styles from './TextField.module.scss';

const TextField = ( {
	field,
	form: { touched, errors },
	className,
	label,
	type = 'text',
	inputColor = 'bianco',
	borderRadius,
	readonly,
	textarea = false,
	height = '50px',
	...props
} ) => (
	<div className={ clx(
		styles.field__wrapper,
		styles[`${className}`],
		{ [styles['full-height']]: textarea && height === '100%' },
	) }>
		{ label && <label className={ styles['field__label'] } htmlFor={ field.name }>{ label }</label> }
		{ textarea ?
			<textarea
				className={ clx( styles['field__textarea'], styles[`${inputColor}`] ) }
				{ ...field }
				{ ...props }
				style={ {
					borderRadius: `${borderRadius}`
				} }
			/> :
			<input
				className={ clx( styles['field__input'], styles[`${inputColor}`] ) }
				type={ type }
				{ ...field }
				{ ...props }
				style={ {
					borderRadius: `${borderRadius}`,
					height
				} } />
		}
		{ touched[field.name] &&
				errors[field.name] && <div className={ styles.error }>{ errors[field.name] }</div> }
	</div>
);

export default TextField;
