import { createTheme } from '@material-ui/core/styles';

const colors = {
	whiteColor: '#FFF7FA',
	grayColor: '#ededed80',
	darkGrayColor: '#51676C',
	lightGrayColor: '#ededed',
	greenColor: '#067C89',
	biancoColor: '#ecf7f8',
	biancoLightColor: '#EBFCFE',
	primaryColor: '#078EA6',
	primaryDarkColor: '#0f687c',
	primaryLightColor: '#08a6b6',
	blackColor: '#333333',
	neroColor: '#034349',
	redColor: '#fd7072',
	rossoColor: '#FC440F',
	gialloColor: '#CFD11A',
	blueColor: '#151ee7',
};
const width = window.screen.width > 1450;
const muiTheme = createTheme({
	overrides: {
		MuiPaper: {
			root: {
				boxShadow: 'none',
				backgroundColor: colors.whiteColor,
			},
			elevation4: {
				boxShadow: 'none',
			}
		},

		MuiTableCell: {
			root: {
				wordBreak: 'break-word',
				'&.centeredHeader': {
					'& span': {
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					},
					'& div': {
						textAlign: 'center'
					}
				},
				'&.centeredColumn': {
					textAlign: 'center'
				},
				'&.boldHeader': {
					'& div': {
						fontWeight: 'bold',
						fontSize: width ? "medium" : "small"
					}
				}
			},
			footer: {
				display: 'flex',
				justifyContent: 'center'
			}
		},

		MUIDataTableHeadCell: {
			data: {
				fontWeight: 'bold',
				fontSize: width ? "medium" : "small"
			},
			fixedHeader: {
				backgroundColor: colors.whiteColor,
			}
		},

		MUIDataTableSelectCell: {
			headerCell: {
				backgroundColor: colors.whiteColor,
			},
		},

		MUIDataTableToolbar: {
			titleRoot: {
				'& h6': {
					color: colors.primaryColor,
					fontWeight: 'bold'
				}
			},

			filterPaper: {
				'& > div': {
					paddingTop: '48px'
				}
			}
		}
	}
});

export default muiTheme;
