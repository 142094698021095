import React from 'react';
import PrimaryButton from '../../../../../../Common/Buttons/PrimaryButton';
import Modal from '../../../../../../Common/Modal';

import styles from './UploadCategoryModal.module.scss';
import {useMutation} from "@apollo/client";
import {
  Field,
  Form,
  Formik
} from "formik";
import * as yup from "yup";
import TextField from "../../../../../../Common/TextField";
import {CREATE_CATEGORY} from "../../../../../api/mutations";
import {GET_CATEGORIES} from "../../../../../api/queries";

const UploadTypologyModal = ({
                               isModalOpen,
                               setUploadTypologyModalOpen,
                               categories,
                             }) => {
  const initialValues = {
    name: ""
  };

  const validationSchema = {
    name: yup.string().required('Is required'),
  };
  const [addCategory] = useMutation(CREATE_CATEGORY, {
    refetchQueries: [GET_CATEGORIES],
    awaitRefetchQueries: true,
  });

  console.log(categories);
  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setUploadTypologyModalOpen(false);
    }}
  >
    <div className={styles['modal__upload']}>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={async values => {
          console.log(values);
          await addCategory({
            variables: values
          });
          setUploadTypologyModalOpen(false);
        }}
      >
        {() => {
          return (
            <Form>
              <Field name='name' id='name'
                     label='Nome Tipologia'
                     component={TextField}
                     type='text'
                     borderRadius='5px'
                     inputColor="white"
              />
              <PrimaryButton
                type='submit'
                fontSize='18px'
                text='Aggiungi Tipologia'
                width='100%'
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  </Modal>;
};

export default UploadTypologyModal;
