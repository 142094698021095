import React from 'react';
import Modal from '../../../../../../Common/Modal';
import {useMutation} from "@apollo/client";
import {DELETE_FACILITY} from "../../../../../api/mutations";
import {GET_FACILITIES} from "../../../../../api/queries";
import ConfirmModal from "../../../../../../Common/ConfirmModal";

const DeleteFacilityModal = ({
                                 isModalOpen,
                                 setDeleteFacilityModalOpen,
                                 facilityIdToDelete
                             }) => {
  const [deleteFacility] = useMutation(DELETE_FACILITY, {
    refetchQueries: [GET_FACILITIES],
    awaitRefetchQueries: true,
  });

  const confirmModal = async () => {
    await deleteFacility({
      variables: {
        facilityId: facilityIdToDelete
      }
    });
    setDeleteFacilityModalOpen(false);
  };
  return <Modal
    isModalOpen={isModalOpen}
    setIsModalOpen={() => {
      setDeleteFacilityModalOpen(false);
    }}
  >
    <ConfirmModal
      setConfirmModalOpen={() => {
        setDeleteFacilityModalOpen(false);
      }}
      confirmModal={confirmModal}
    />
  </Modal>;
};

export default DeleteFacilityModal;
