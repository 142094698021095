import {
  useEffect, useState
} from 'react';

import ActiveImageInfo from './containers/ActiveImageInfo';
import ConfirmRemoveImageModal from './containers/ConfirmRemoveImageModal';
import GalleryList from './containers/GalleryList';
import UploadImageModal from './containers/UploadImageModal';
import styles from './FieldGallery.module.scss';

const FieldGallery = ({
                        gallery,
                        id,
                        coverId
                      }) => {
  const [activeImage, setActiveImage] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [uploadImageModalOpen, setUploadImageModalOpen] = useState(false);

  useEffect(() => {
    if (gallery) {
      // setActiveImage(gallery?.sort((img1, img2) => img1.order - img2.order)[0]);
      setActiveImage(gallery[0]);
    }
  }, [gallery, id]);

  return <div className={styles.gallery__wrapper}>
    <ActiveImageInfo
      activeImage={activeImage}
      id={id}
      coverId={coverId}
      setConfirmModalOpen={setConfirmModalOpen}
      setUploadImageModalOpen={setUploadImageModalOpen}
    />
    <GalleryList
      id={id}
      coverId={coverId}
      gallery={gallery}
      activeImage={activeImage}
      setActiveImage={setActiveImage}
    />
    <ConfirmRemoveImageModal
      confirmModalOpen={confirmModalOpen}
      setConfirmModalOpen={setConfirmModalOpen}
      fieldId={id}
      idImage={activeImage?.id}
    />
    <UploadImageModal
      id={id}
      isModalOpen={uploadImageModalOpen}
      setUploadImageModalOpen={setUploadImageModalOpen}
    />
  </div>;
};

export default FieldGallery;
