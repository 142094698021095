import {useEffect, useState} from 'react';

import FolderIcon from '../../../../../../../../../assets/svg/folder.svg';
import {fileReaderToBase64} from '../../../../../../../../../helpers';
import PrimaryButton from '../../../../../../../../Common/Buttons/PrimaryButton';
import Modal from '../../../../../../../../Common/Modal';
import DragAndDropContainer from './DragAndDropContainer';
import styles from './UploadImageModal.module.scss';
import {useMutation} from "@apollo/client";
import {ADD_PICTURE_TO_ESTABLISHMENT, UPLOAD_IMAGE} from "../../../../../../../api/mutations";
import {GET_ESTABLISHMENT} from "../../../../../../../api/queries";

const UploadImageModal = ({
                            id, isModalOpen, setUploadImageModalOpen
                          }) => {
  const [uploadImages, setUploadImages] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [error, setError] = useState(false);

  const [addDetailsImage] = useMutation(UPLOAD_IMAGE);
  const [addPictureToEstablishment] = useMutation(ADD_PICTURE_TO_ESTABLISHMENT, {
    refetchQueries: [{
      query: GET_ESTABLISHMENT,
      variables: {
        establishmentInput: {
          id: Number(id),
        }
      }
    }],
    awaitRefetchQueries: true,
  });
  const uploadImgAddAddToEstablishment = async () => {
    if (uploadedFile) {
      const {data: {uploadPicture}} = await addDetailsImage({
        variables: {
          picture: uploadedFile
        }
      });
      if (uploadPicture?.uploadPicture?.length) {
        await addPictureToEstablishment({
          variables: {
            addPicturesToEstablishmentGalleryInput: {
              establishmentId: id,
              pictureIds: uploadPicture.uploadPicture.map(picture => picture.id)
            }
          }
        });
        closeModal();
      }
    }
  };
  useEffect(() => {
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [error]);

  const closeModal = () => {
    setUploadImages([]);
    setUploadedFile([]);
    setUploadImageModalOpen(false);
  };

  return <Modal
    width="520px"
    isModalOpen={isModalOpen}
    setIsModalOpen={closeModal}
  >
    <div className={styles['modal__upload-image']}>
      <DragAndDropContainer setUploadedFile={setUploadedFile} setUploadImages={setUploadImages} setError={setError}>
        <div
          className={styles['upload-img__btn']}
        >
          <label htmlFor="img" className={styles['btn__label']}>
            <img src={FolderIcon} alt="upload"/>
            <span>Drag and Drop files here or click</span>
            {error && <p className={styles.error}>{error}</p>}
          </label>
          <input
            style={{
              width: '0.1px',
              height: '0.1px',
              opacity: '0',
              overflow: 'hidden',
              position: 'absolute',
              zIndex: '-1',
            }}
            onChange={async e => {
              e.persist();
              const img = Object.values(e.target.files);
              if (img) {
                const base64ImageUrl = await fileReaderToBase64(
                  img
                );
                console.log(img[0]);
                setUploadImages(prevState => [...prevState, base64ImageUrl]);
                setUploadedFile(images => [...images, img[0]]);
              }
            }}
            name="img"
            id="img"
            type="file"
            accept="image/png, image/jpeg, image/jpg"
          />
        </div>
      </DragAndDropContainer>
      {uploadImages?.length ?
        <div className={styles.preview__wrapper}>
          {uploadImages?.map((image, idx) =>
            <div key={idx} className={styles['uploaded-image__wrapper']}
                 onClick={() => {
                   setUploadImages(images => images?.filter((img, i) => i !== idx));
                   setUploadedFile(files => files?.filter((img, i) => i !== idx));
                 }}
            >
              <img src={image} alt=''
                   className={styles['uploaded-image']}/>
            </div>)
          }
        </div> :
        null}
      <PrimaryButton
        onClick={uploadImgAddAddToEstablishment}
        text='Carica'
        width='150px'
      />
    </div>
  </Modal>;
};

export default UploadImageModal;
