import { useMutation, useQuery } from "@apollo/client";
import { GET_ESTABLISHMENT, GET_FACILITIES } from "../../../../../../../api/queries";
import { EDIT_ESTABLISHMENT } from "../../../../../../../api/mutations";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Select from "react-select";
import styles from "../../EstablishmentDisabilitiesAndFacilitiesAndTags.module.scss";
import PrimaryButton from "../../../../../../../../Common/Buttons/PrimaryButton";
import React, { useState } from "react";
import CheckIcon from "../../../../../../../../../assets/svg/check-icon.svg";
import IconButton from "../../../../../../../../Common/Buttons/IconButton";
import CloseIcon from "../../../../../../../../../assets/svg/cross-icon.svg";
import PencilImg from "../../../../../../../../../assets/svg/pencil.svg";


const EstablishmentFacilities = ({ facilities, id }) => {
  const allFacilities = useQuery(GET_FACILITIES, {});
  const [editFormOpen, setEditFormOpen] = useState(false);
  const [editEstablishmentFacilities] = useMutation(EDIT_ESTABLISHMENT, {
    refetchQueries: [{
      query: GET_ESTABLISHMENT,
      variables: {
        establishmentInput: {
          id: Number(id),
        }
      }
    }],
    awaitRefetchQueries: true,
  });
  const initialValues = {
    facilityIds: facilities?.map(item => item.id) || [],
    establishmentId: id
  };

  const validationSchema = {
    facilityIds: yup.array().min(1, 'Obbligatorio'),
  };
  return (
    <div className={styles.facilityAndDisability__inner}>
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape(validationSchema)}
        onSubmit={async values => {
          await editEstablishmentFacilities({
            variables: {
              editEstablishmentInput: {
                id: values.establishmentId,
                facilityIds: values.facilityIds,
              }
            }
          });
          setEditFormOpen(false);
        }}
      >
        {({
          errors,
          touched,
          values,
          setFieldValue
        }) => {
          return (
            <Form>
              <div className={styles.facilityAndDisability__inner__form}>
                <label htmlFor={"facilityIds"}>Facilities</label>
                {editFormOpen ?
                  (
                    <div className={styles.actions}>
                      <PrimaryButton
                        text='Salva modifica'
                        leftIconSrc={CheckIcon}
                        type={'submit'}
                        fontSize="10px"
                        height='25px'
                        padding='6px 10px'
                        borderRadius='5px'
                      />
                      <IconButton
                        padding='0'
                        onClick={() => setEditFormOpen(false)}
                        iconSrc={CloseIcon}
                      />
                    </div>
                  ) :
                  (
                    <IconButton
                      onClick={() => setEditFormOpen(true)}
                      backgroundColor='#5E239D'
                      iconSrc={PencilImg}
                    />
                  )}
              </div>
              <Select
                isMulti
                name="facilityIds"
                placeholder={"Seleziona Attività"}
                isDisabled={!editFormOpen}
                value={allFacilities?.data?.facilities?.filter(item => values.facilityIds.includes(
                  item.id)).map(item => ({ value: item.id, label: item.name }))}
                onChange={data => setFieldValue("facilityIds", data.map(item => item.value))}
                options={allFacilities?.data?.facilities?.map(item => ({ value: item.id, label: item.name }))}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              {
                touched.disabilityIds &&
                errors.disabilityIds && <div className={styles.error}>{errors.disabilityIds}</div>
              }
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};


export default EstablishmentFacilities;
